import {React, ReactDOM} from "react-library/library";
import CampaignForm from "react-library/library/components/campaign-form/CampaignForm";

import './style.scss';
import {Container} from "react-library/library/components/container/Container";

const partners = [
    {name: "Päästame Eesti Metsad MTÜ (petitsiooni algataja)", link: "https://www.savetheforest.ee"},
    {name: "Eesti Roheline Liikumine", link: "https://www.roheline.ee/"},
    {name: "Eesti Metsa Abiks", link: "https://www.facebook.com/groups/321102291623006/"},
    {name: "Eesti Looduskaitse Selts", link: "http://www.elks.ee"},
    {name: "Eestimaa Looduse Fond", link: "https://elfond.ee/"},
    {name: "Eesti Loomakaitse Selts", link: "https://loomakaitse.ee/"},
    {name: "Eesti Ökokogukondade ühendus", link: "https://www.kogukonnad.ee/"},
    {name: "Eesti Ornitoloogiaühing", link: "https://www.eoy.ee/"},
    {name: "Eesti Loodusturismi ühing", link: "https://www.loodusturism.com/"},
    {name: "Soomaa.com / OÜ Viis aastaaega", link: "https://soomaa.com"},
    {name: "MTÜ Niilusoo", link: "https://www.facebook.com/niilusoo/ "},
    {name: "Keskkonnasõbraliku elustiili portaal Bioneer", link: "https://bioneer.ee/"},
    {name: "Gaia kool", link: "http://gaiakool.ee/"},
    {name: "Studio Viridis Loodusharidus", link: "http://www.studioviridis.ee/"},
    {
        name: "Extinction Rebellion Tallinn",
        link: "https://www.facebook.com/pages/category/Environmental-Conservation-Organization/Extinction-Rebellion-Tallinn-111067143809317/"
    },
    {name: "Elurikkuse Erakond", link: "https://elurikkuseerakond.ee/"},
    {name: "Erakond Eestimaa Rohelised", link: "https://erakond.ee/"},
    {name: "MTÜ Roheline Läänemaa", link: "https://www.rohelinelaanemaa.ee/"},
    {name: "MTÜ Roheline Pärnumaa", link: "https://www.facebook.com/rohelineparnumaa/"},
    {name: "MTÜ Rebala Kultuuriruum", link: "https://pysimetsayhistu.ee/"},
    {name: "Eesti Püsimetsaühistu", link: "#"},
    {name: "Forestly Eesti TÜ", link: "https://forestly.ee/"},
    {name: "Eesti Metsloomaühing", link: "https://www.metsloom.ee/"},
    {name: "Raplamaa Loodushoiu MTÜ", link: "https://www.facebook.com/raplamaaloodushoid/"},
    {name: "MTÜ Aade", link: "#"},
    {name: "Muraste Looduskool", link: "http://www.murastelooduskool.ee/"},
    {name: "Lohusalu Poolsaare Loodusselts", link: "https://lohusaluloodus.ee/"},
    {name: "Tartu Üliõpilaste Looduskaitsering", link: "https://ring.ee/"},
    {name: "Nõmme Heakorra Selts", link: "https://www.nhs.ee/"},
    {name: "Hiiu Tuul MTÜ", link: "https://hiiutuul.ee"},
    {name: "Nõmme Tee Selts", link: "http://www.nommeteeselts.ee/"}
];

const app = (
    <React.Fragment>
        <div className={"bg-cover bg-auto bg-center relative z-0"} style={{
            backgroundImage: `url(${require("./assets/mushroom_background.jpg")})`
        }}>
            <div className="bg-black absolute top-0 bottom-0 left-0 right-0 opacity-50 -z-10">a</div>

            <Container additionalClasses={["py-10 md:py-20 z-10"]}>
                <div className="flex-none md:flex lg:flex items-center">
                    <div className="w-full md:w-1/2 text-white text-center md:text-left">
                        <img
                            src={require("./assets/logo.png")}
                            alt="Päästame Eesti Metsad MTÜ"
                            className="h-40 mb-10 md:mb-16 inline-block"
                        />

                        <h2 className="text-5xl mb-6 md:mb-10 font-bold">Eesti Metsapetitsioon</h2>
                        <h3 className="text-2xl w-full md:w-3/4 lg:w-3/4 mb-6 md:mb-0">
                            Loe allolev petitsioon läbi ja anna oma nõusolek siin!
                        </h3>
                    </div>

                    <div className="w-full md:w-1/2">
                        <div className="rounded bg-white">
                            <CampaignForm
                                campaignId={Number(process.env.API_CAMPAIGN_ID)}
                                showFooter={true}
                                showBankLinks={false}
                                goalSignCount={10000}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>

        <div className="container mx-auto pt-10 pb-10">
            <div className="w-full">
                <p className="mb-4 font-bold">
                    Eesti Vabariigi Valitsusele, Riigikogule, Presidendile.
                </p>
                <p className="mb-4">
                    Metsal on inimese jaoks ökoloogiline, majanduslik, sotsiaalne ja kultuuriline väärtus ning nende
                    hüvede kasutamise vahel peab valitsema tasakaal. Praegu seda tasakaalu ei ole. Täna on mets
                    peamiselt puidutöösturite tootmisressurss, metsas justkui jõude vedeleva rikkuse
                    allikas. Tasakaalustatud metsandus on ökoloogiliste, kultuuriliste, majanduslike ja sotsiaalsete
                    väärtuste järjepidev taastootmine.
                </p>

                <p className="mb-4">
                    Nõuame Eesti Vabariigi valitsuselt ja Riigikogu saadikutelt nii koalitsioonis kui opositsioonis
                    seadusandluse ettevalmistamist viivitamatuks üleminekuks tasakaalustatud metsandusele.
                </p>

                <p className="mb-4">
                    Jätkusuutmatu metsamajandamise tagajärjel tekitatud probleemide lahendamist ei saa jätta meie laste
                    ning lastelaste õlule. Me ei saa minna ajalukku põlvkonnana, kes teadlikult pärandab oma
                    järeltulijatele elamiskõlbmatu maa. Hästi toimivad, liigirikkad ja stabiilsed ökosüsteemid on
                    olulised mitte ainult kultuurilistel või looduskaitselistel põhjustel, vaid ennekõike seetõttu, et
                    nendega on seotud meile, inimestele, eluks vajalikud looduslikud hüved. Kliima, mullastiku,
                    veesüsteemide ja elurikkuse elujõulisusest ning terviklikkusest sõltub meie igapäevane –tõsi seni
                    küll veel teadvustamata –heaolu. Elurikkuse kadu arutult suure metsaraie tagajärjel mõjutab lõpuks
                    nii magevee kättesaadavust, toidutootmist kui ka kultuurilisi ja riigikaitselisi aspekte.
                </p>

                <p className="mb-4">
                    Tasakaalustatud metsandusele ülemineku esimesed sammud on:
                </p>
            </div>
            <div className="w-full">
                <div className="mb-10 px-10 py-6 border rounded">
                    <div className="font-bold mb-5 text-xl text-primary">
                        1. Raiemahtude vähendamine jätkusuutlikule tasemele
                    </div>

                    <div>
                        Juba aastaid kestnud üleraie korvamiseks tuleb lubatud raiemaht viia alla 8 miljoni tihumeetri
                        aastas, soovitavalt 4-6 miljonile tihumeetrile.<br/>
                        Eesmärgiks tuleb seada pigem hinnaliste ja innovaatiliste kestvustoodete tootmine, mis võimaldab
                        raiuda valikuliselt ja senisest
                        vähem.<br/>
                        Praeguse tempoga raiet jätkates ammenduvad riigile kuuluvad hetkel raiekõlbulikud metsad umbes
                        kümne aasta jooksul. Juba praegu toimuvad seetõttu suuremahulised raied ka kaitsealadel ja
                        piiranguvööndites. Kaitsealad tuleb hoida seaduslikult kindla kaitse all, mitte võimaldada
                        erinevate JOKK-skeemide kasutamist. Kaitsealade kaitse-eeskirjad ei tohi lubada lageraiet
                        piiranguvööndites. Seda nii era-kui riigimetsades.Metsa kui taastuva loodusvara kriitiline varu
                        tuleb kindlaks määrata, nii nagu seda näeb ette säästva arengu seadus ja seda ka hoida.
                    </div>
                </div>

                <div className="mb-10 px-10 py-6 border rounded">
                    <div className="font-bold mb-5 text-xl text-primary">2. Metsamajandamine olgu loodushoidlik</div>

                    <div>
                        Eesti metsanduspoliitikas baseerub puidutootmine pinnase jaoks liiga rasketel metsamasinatel
                        ning ulatuslikel lageraiealadel. Järjest soojemate talvede tõttu läbi külmumata pinnas ja
                        vahelduvalt põuased-vihmased-tormised suved suurendavad niigi ebasobivate majandusvõtete
                        negatiivset mõju veelgi. Jätkusuutlikult mõtlev peremees ei lõhuks metsamulda ja kasvukohale
                        omast järelkasvu, vaid säästaks alustaimestikku ja hooliks nii nähtavast kui nähtamatust
                        elustikust oma metsas. <br/>
                        Metsakombainide liisingumaksed ei tohi suunata riiklikku metsapoliitikat – vastupidi – ootame
                        riigilt igakülgset toetust uute innovaatiliste metsamajandamisviiside
                        otsimiseleja leidmisele.Erametsanduses tuleb toetuste skeemid ümber kujundada ja makstavaid
                        toetusi täiendada, et soodustada loodushoidlikku metsamajandamist ja püsimetsandust.
                    </div>
                </div>

                <div className="mb-10 px-10 py-6 border rounded">
                    <div className="font-bold mb-5 text-xl text-primary">3. Raierahu nii riigi- kui erametsadesse</div>

                    <div>
                        Kõikides Eesti metsades, nii riigi-kui erametsades kolm kuud kestev kevad-suvine rahuaeg on
                        miinimum, mida tuleb meid ümbritsevale elusloodusele uue eluringi alustamiseks võimaldada.Praegu
                        kehtib raierahu 15. aprillist 15. juunini ainult riigimetsas ja sedagi osaliselt. Peaaegu igal
                        pool on lubatud teostada rasketehnikaga hooldus-ja valgustusraieid ning metsa seisundit
                        parandavaid raieid. 2020. aasta kevadel võis teostada ulatuslikke lageraieid ka
                        kuuse-kooreüraski vastase tegevuse raames.Metsalindude arvukus on teadlaste andmetel vähenenud
                        viimastel aastakümnetel lausa veerandi võrra. <br/>
                        Linnud on keskkonnaseisundi hindamisel väga
                        headeks indikaatoriteks, sest ökosüsteemides toimuvad muutused kajastuvad kiiresti nende
                        arvukuses. Nad annavad meile tagasisidet sellest, kuidas me oleme oma ühise elukeskkonnaga
                        viimastel aastakümnetel ümber käinud
                    </div>
                </div>

                <div className="mb-10 px-10 py-6 border rounded">
                    <div className="font-bold mb-5 text-xl text-primary">
                        4. Eesti metsanduse juhtimise tulevik – Riigimetsa Majandamise Keskusest Riigimetsa Kaitse
                        Keskuseks
                    </div>

                    <div>
                        Võttes arvesse kõikjal maailmas tehtavaid pingutusi kliima ja elurikkuse kaitseks, sh Euroopa
                        Liidu rohelist kokkulepet ja eespool mainitud eesmärke, tuleb muuta ka Eesti praegust metsanduse
                        juhtimise süsteemi. Tuleb loobuda puidul kui toorainel põhinevast kitsast eesmärgist ja taastada
                        laiapõhjaline metsandus, mis teeb kõikide sidusrühmadega avatult ja tõeliselt kaasavat koostööd,
                        on paindlik ja innovaatiline puidu lisaväärtuse loomisel ning metsa kõrvalsaaduste ja muude
                        ökoloogiliste teenuste kasutamisel põhineva ettevõtluse lubamisel, kaaludes alati põhjalikult
                        nende tegevuste võimalikku ruumilist või looduskeskkonnale suunatud mõju.
                        <br/>
                        Ajal, mil maailmas on kiirenenud kuues massiline liikide väljasuremine, peab uuendatud RMK olema
                        majandamise asemel metsa kui elukeskkonna kaitsest lähtuv asutus –RMK 2.0 ehk Riigimetsa Kaitse
                        Keskus.
                        <br/>
                        Selle eesmärgi saavutamiseks on vajalik põhjalik reform, alustades RMK nõukogu koosseisu
                        muutmisest, nii et seal oleksid võrdselt esindatud metsa nelja peamise väärtuse –ökoloogilise,
                        kultuurilise, majandusliku ja sotsiaalse –esindajad.
                    </div>
                </div>


                <div className="mb-10 px-10 py-6 border rounded">
                    <div className="font-bold mb-5 text-xl text-primary">
                        5. Mets on osa Eesti ja eestlase identiteedist
                    </div>

                    <div>
                        Selle petitsiooni ja kobarmeeleavaldusega 16. augustil tahame teha kuuldavaks nende inimeste
                        hääled, kes vajavad kodulähedasi kogukonnametsi, kes mõistavad vanade loodusmetsade,
                        vääriselupaikade ja rohekoridoride ning kogu elurikkuse olulisust meie enda heaolu loomisel ja
                        kestmisel. Anname
                        sõna inimestele, kes austavad muistseid pühapaiku, tunnevad metsa ravitoimet või ammutavad jõudu
                        tema esteetilisusest. Anname sõna inimestele, kes peavad lugu metsaandidest ja rikastavad
                        nendega oma pere toidulauda. <br/>
                        Metsa kultuurilisi, sotsiaalseid ja ökoloogilisi väärtusi ei saa mõõta rahas, sest me ei maksa
                        metsale meie elu lahutamatuks osaks olevate teenuste eest. Mets on osa Eesti ja eestlase
                        identiteedist. Hävitades metsa hävime ka ise. Aeg on muuta suhtumist, aeg on alustada uut,
                        ökoloogilise mitmekesisuse ja liigirikkuse taastamise ning jätkusuutliku metsamajandamise
                        ajastut.
                    </div>
                </div>

            </div>

            <h3 className="mb-4 text-xl font-bold">Petitsiooniga on ühinenud järgmised organisatsioonid:</h3>

            {
                partners.map(({name, link}, i) => (
                    <>
                        <a key={name} href={link}
                           className={`p-3 font-bold inline-block ${i % 2 === 1 ? "text-primary" : "text-black"}`}>{name}</a>

                        {i < partners.length - 1 ?
                            <p className="inline-block w-1 h-1 bg-black rounded align-middle"></p> : ""}
                    </>
                ))
            }
        </div>
    </React.Fragment>
);

ReactDOM.render(app, document.getElementById("app"));
